<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-5">
      <div class="m-2"></div>
      <div class="m-1">
        <!-- table -->
        <vue-good-table
          :columns="tableColumns"
          :rows="paginateData.data"
          :pagination-options="{
            enabled: true,
          }"
          mode="remote"
          :line-numbers="true"
          theme="polar-bear"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          :isLoading.sync="loading"
        >
          <!-- loading -->
          <template slot="loadingContent">
            <b-spinner
              variant="danger"
              v-show="loading"
              class="align-middle"
            ></b-spinner>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'serial'">
              <strong class="text-primary">
                {{ props.row.serial }}
              </strong>
            </span>

            <span v-else-if="props.column.field === 'trans_date'">
              {{ props.row.trans_date | formatDate }}
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <b-button
                variant="success"
                size="sm"
                class="px-50"
                :to="{
                  name: 'clearance.transactions.view',
                  params: { id: props.row.id },
                }"
              >
                <feather-icon icon="EyeIcon" class="mr-25" />
                <span>{{ $t("View") }}</span>
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> {{ $t("Showing 1 to") }} </span>

                <b-form-select
                  v-model="serverParams.perPage"
                  :options="['10', '25', '50']"
                  class="mx-1"
                  @input="onPerPageChange"
                />
                <span class="text-nowrap">
                  {{ $t("of") }} {{ paginateData.total }} {{ $t("entries") }}
                </span>
              </div>
              <div>
                <pagination
                  :data="paginateData"
                  :limit="2"
                  @pagination-change-page="onPageChange"
                  class="mt-1 mb-0"
                >
                </pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";

import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BPaginationNav,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";

export default {
  data() {
    return {
      paginateData: {},
      tableColumns: [
        {
          label: this.$t("Transaction Date"),
          field: "trans_date",
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: this.$t("Serial"),
          field: "serial",
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: this.$t("Policy Number"),
          field: "policy_number",
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: this.$t("Import Declaration"),
          field: "import_declaration",
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },

        {
          label: this.$t("Transaction Type"),
          field: "transaction_type.name",
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: this.$t("Arrival Port"),
          field: "arrival_port.name",
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: this.$t("Client"),
          field: "client.name",
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },

        {
          label: this.$t("Action"),
          field: "action",
          width: "300px",
          sortable: false,
        },
      ],
      serverParams: {
        sortField: "id",
        sortType: "DESC",
        perPage: 10,
        page: 1,
      },
      loading: false,
    };
  },

  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BPaginationNav,
    BFormSelect,
    BSpinner,
    VueGoodTable,
  },

  created() {
    this.$store.commit("START_LOADING");
    this.loadItems();
  },
  methods: {
    loadItems() {
      this.loading = true;
      this.$http
        .get("/crm/client/closed-transactions")
        .then((response) => {
          this.paginateData = response.data.transactions;
        })
        .finally(() => {
          this.$store.commit("STOP_LOADING");
          this.loading = false;
        });
    },

    updateParams(newProps) {
      if (newProps.columnFilters != null) {
        this.serverParams.columnFilters = {
          ...this.serverParams.columnFilters,
          ...newProps.columnFilters,
        };
      } else {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      }
      this.loadItems();
    },
    onPageChange(page) {
      this.updateParams({ page: page });
    },
    onPerPageChange(perPage) {
      this.updateParams({ page: 1 });
      this.updateParams({ perPage: perPage });
    },
    onSortChange(params) {
      this.updateParams({
        sortType: params[0].type,
        sortField: params[0].field,
      });
    },
    onColumnFilter(params) {
      this.updateParams(params);
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/vue/libs/vue-good-table.scss";
</style>
